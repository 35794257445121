import {inject, Signal} from '@angular/core';
import {toSignal} from '@angular/core/rxjs-interop';
import {Store} from '@ngxs/store';

export type GetByIdFn<T> = (id: string) => T;

export type DeepPartial<T> = T extends object
  ? {
      [P in keyof T]?: DeepPartial<T[P]>;
    }
  : T;

// The following does not work without any, since the state slices can be of dynamic length
// and types.
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function readStoreSignal<T>(selector: (...states: any[]) => T): Signal<T> {
  const store = inject(Store);
  return toSignal(store.select(selector), {
    initialValue: store.selectSnapshot(selector)
  });
}
