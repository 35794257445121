import {ChangeDetectionStrategy, Component, input} from '@angular/core';
import {TranslateModule} from '@ngx-translate/core';

@Component({
  selector: 'msa-empty-list-box',
  templateUrl: './msa-empty-list-box.component.html',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [TranslateModule]
})
export class MsaEmptyListBoxComponent {
  public message = input.required<string>();
}
