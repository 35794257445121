import {HttpErrorResponse} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {ErrorCodeTextMap} from '@shared/core/constants/error-code-text-map.const';
import {TranslationString} from '@shared/shared-module/utils/translation.utils';
import {EMPTY, throwError} from 'rxjs';
import {ErrorMessageDto} from '../../../../../projects/admin-query/src/app/core/api/generated/msa-duty-service';
import {MessageType} from '../../components/enums/messageType';
import {SnackbarService} from '../../components/msa-snackbar/service/snackbar.service';
import {LoggingService} from '../logging/base-logging.service';

@Injectable({
  providedIn: 'root'
})
export class ErrorMessageHandler {
  constructor(
    private snackbarService: SnackbarService,
    private loggingService: LoggingService
  ) {}

  ERROR_LIST_DURATION_MILLISECONDS: number = 90000;
  ERROR_MESSAGE: TranslationString = 'i18n.common.error.generic';

  public logAndThrow(error: unknown, errorMessage: TranslationString = this.ERROR_MESSAGE) {
    this.log(error, errorMessage);
    return throwError(() => error);
  }

  public logAndIgnore(error: unknown, errorMessage: TranslationString = this.ERROR_MESSAGE) {
    this.log(error, errorMessage);
    return EMPTY;
  }

  private log(error: unknown, errorMessage: TranslationString = this.ERROR_MESSAGE) {
    this.loggingService.error(error);

    this.loggingService.error(error, errorMessage);
    if (!(error instanceof HttpErrorResponse)) return;

    if (error.status >= 500) {
      this.genericError(errorMessage);
    } else if (error.status >= 400) {
      const errorMessages = error.error as ErrorMessageDto[];
      this.handleErrorMessage(errorMessages);
    }
  }

  private handleErrorMessage(errorMessages: ErrorMessageDto[]) {
    const snackbarTexts: TranslationString | TranslationString[] = [];
    const interpolateParams: {[key: string]: string}[] = [];

    if (!Array.isArray(errorMessages)) {
      this.genericError();
      return;
    }
    errorMessages.forEach(errorMessage => {
      if (!errorMessage.errorCode) return;

      const errorText: TranslationString = ErrorCodeTextMap[errorMessage.errorCode] ?? 'i18n.common.error.generic';
      snackbarTexts.push(errorText);

      if (errorMessage.properties) {
        interpolateParams.push(errorMessage.properties);
      } else {
        interpolateParams.push({});
      }
    });

    this.snackbarService.openSnackbar({
      text: snackbarTexts,
      type: MessageType.Error,
      interpolateParams: interpolateParams,
      durationMilliseconds: this.ERROR_LIST_DURATION_MILLISECONDS
    });
  }

  public genericError(errorMessage: TranslationString = this.ERROR_MESSAGE) {
    this.snackbarService.openSnackbar({text: [errorMessage], type: MessageType.Error});
  }
}
